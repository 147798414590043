import React, { useEffect, useState } from 'react';
import Switch from './components/Switch';
import Counter from './components/Counter';
import Logo from './components/Logo';
import RoinextLogo from './components/RoinextLogo';
import Share from './components/Share';
import './app.css';
import MobileBG from './components/MobileBG';

const xMan = 400;
const xFat = 550;
const xWoman = 300;
const xChildren = 150;
const koff = 1.1;

function App() {
  const [count, setCount] = useState(0);
  const [onion, setOnion] = useState(0);
  const [man, setMan] = useState(0);
  const [fat, setFat] = useState(0);
  const [woman, setWoman] = useState(0);
  const [children, setChildren] = useState(0);

  useEffect(() => {
    calculateKebab();
  }, [man, woman, children, fat]);

  const calculateKebab = () => {
    const calc:number = Math.ceil(( man * xMan + fat * xFat + woman * xWoman + children * xChildren ) * koff / 100) / 10;
    const calcOnion = Math.ceil((calc * 300) / 100) / 10;
    setCount(calc);
    setOnion(calcOnion);
  }

  const switchHandlerMan = (plus?: boolean) => {
    if (plus) {
      if (man < 99) setMan(man+1);
    }
    else {
      if (man != 0) setMan(man-1);
    }
  }
  const switchHandlerFat = (plus?: boolean) => {
    if (plus) {
      if (fat < 99) setFat(fat+1);
    }
    else {
      if (fat !== 0) setFat(fat-1);
    }
  }
  const switchHandlerWoman = (plus?: boolean) => {
    if (plus) {
      if (woman < 99) setWoman(woman+1);
    }
    else {
      if (woman !== 0) setWoman(woman-1);
    }
  }
  const switchHandlerChildren = (plus?: boolean) => {
    if (plus) {
      if (children < 99) setChildren(children+1);
    }
    else {
      if (children !== 0) setChildren(children-1);
    }
  }

  return (
    <div className='wrapper'>
      <MobileBG />
      <div className="container">
        <Logo />
        <div className="body">
          <Counter count={count} onion={onion}/>
          <div className="divider"></div>
          <div className="switches-wrapper">
            <div className="switches-title">Сколько человек?</div>
            <div className='switches'>
              <Switch count={man} counterHandler={switchHandlerMan} label="Мужчины" />
              <Switch count={fat} counterHandler={switchHandlerFat} label="XXL" />
              <Switch count={woman} counterHandler={switchHandlerWoman} label="Женщины" />
              <Switch count={children} counterHandler={switchHandlerChildren} label="Дети" />
            </div>
            <Share value={count} onion={onion} />
          </div>
          <div className="bg-img-left"></div>
          <div className="bg-img-right"></div>
        </div>
      </div>
      <RoinextLogo />
    </div>
  );
}

export default App;
