import React from 'react'
import './css/counter.css'

interface CounterProps {
    count: number;
    onion?: number;
}

const Counter: React.FC<CounterProps> = ({count, onion}) => {
  if (count === 0) return (
    <div className='count-box'>
      <h1>Сколько нужно мяса для шашлыка?</h1>
    </div>
  );
  return (
    <div className={`count-box ${count !== 0 ? 'visible' : ''}`}>
      <div className="count-title">Маринованного мяса:</div>
      <div className="count-footer">*( Лука для маринада {onion} кг )</div>
      <div className='count-main'>
        <span className='count-number'>{count}</span> кг
      </div>
    </div>
  )
}

export default Counter;