import React from 'react'
import './css/roinext.css'

const RoinextLogo:React.FC = () => {
    return (
        <a href='https://roinext.marketing' target="_blank" className='roinext-logo'>
            <svg width="150" height="43" viewBox="0 0 150 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M80.3203 6.30917L101.609 0.556146C102.818 0.227184 104.049 0.426091 105.067 1.01516C106.084 1.59659 106.872 2.56817 107.201 3.77692L113.328 26.4754C113.657 27.6841 113.458 28.9158 112.869 29.9333C112.288 30.9508 111.316 31.7388 110.108 32.0677C107.132 32.871 90.2494 37.8513 88.6965 37.8513C87.5261 38.1344 86.3405 37.9278 85.3613 37.3617C84.3439 36.7803 83.556 35.8087 83.2271 34.6L77.0998 11.9015C76.7709 10.6928 76.9698 9.46109 77.5588 8.4436C78.1401 7.42611 79.1116 6.63813 80.3203 6.30917ZM102.068 2.27746L80.7792 8.03049C80.0372 8.22939 79.4482 8.71136 79.0963 9.33104C78.7445 9.95071 78.6221 10.7081 78.821 11.4502L84.9559 34.1562C85.1548 34.8907 85.6367 35.4797 86.2563 35.8317C86.8759 36.1836 87.6332 36.306 88.3752 36.1071L109.664 30.3541C110.406 30.1551 110.995 29.6732 111.347 29.0535C111.699 28.4338 111.821 27.6764 111.622 26.9344L105.495 4.23594C105.296 3.49386 104.814 2.90479 104.195 2.55287C103.575 2.20096 102.818 2.07856 102.076 2.27746H102.068Z" fill="#EF7C00"/>
                <path d="M139.337 29.459C139.115 29.459 138.985 29.3672 138.954 29.1759C138.954 29.1759 138.946 29.1071 138.946 29.0765V12.942C138.946 12.766 138.878 12.6054 138.74 12.4677C138.648 12.3759 138.549 12.3147 138.434 12.284C138.38 12.2687 138.319 12.2687 138.258 12.2687H136.552C136.552 12.2687 136.521 12.2687 136.506 12.2687H132.666C132.567 12.2687 132.475 12.2305 132.398 12.1463C132.322 12.0698 132.276 11.9704 132.276 11.848V9.07093C132.276 8.94852 132.314 8.84907 132.398 8.77256C132.475 8.69606 132.567 8.65016 132.666 8.65016H145.724C147.009 8.65016 148.317 8.65016 149.61 8.65016C149.709 8.65016 149.801 8.68841 149.878 8.77256C149.954 8.84907 150 8.94852 150 9.07093V11.848C150 11.9704 149.962 12.0698 149.878 12.1463C149.801 12.2228 149.709 12.2687 149.61 12.2687H144.026C143.827 12.2687 143.659 12.3376 143.521 12.4753C143.383 12.613 143.314 12.7737 143.314 12.9496V29.0841C143.314 29.1224 143.314 29.1606 143.299 29.1989C143.268 29.3595 143.169 29.4437 143.001 29.4666C142.978 29.4666 142.947 29.4666 142.924 29.4666H139.367L139.337 29.459Z" fill="white"/>
                <path d="M0.566067 29.6885C0.405426 29.6885 0.275384 29.6426 0.160641 29.5355C0.0535468 29.436 0 29.306 0 29.1453V18.718C0 18.5573 0.0535468 18.4272 0.160641 18.3125C0.267734 18.2054 0.405426 18.1518 0.566067 18.1518H6.7469C7.54245 18.1518 8.21562 17.8841 8.76638 17.3485C9.31715 16.813 9.58488 16.1628 9.58488 15.4054C9.58488 14.648 9.3095 13.9748 8.76638 13.4316C8.21562 12.8961 7.54245 12.6283 6.7469 12.6283H4.23785C4.01601 12.6283 3.83242 12.7048 3.68708 12.8502C3.53409 12.9955 3.46525 13.1715 3.46525 13.3704V15.6272C3.46525 15.8261 3.3964 16.0021 3.25871 16.1475C3.12102 16.3005 2.93743 16.3693 2.72324 16.3693H0.749656C0.550768 16.3693 0.374828 16.2928 0.229486 16.1475C0.084145 15.9944 0.00764955 15.8261 0.00764955 15.6272V9.30809C0.00764955 9.14743 0.0611964 9.01737 0.16829 8.90262C0.275384 8.79552 0.413076 8.74196 0.573716 8.74196H6.75455C7.98613 8.74196 9.12591 9.04032 10.1586 9.63705C11.1913 10.2338 12.0174 11.0447 12.6218 12.0545C13.2261 13.072 13.5321 14.189 13.5321 15.3977C13.5321 16.4305 13.2949 17.4097 12.8283 18.3278C12.3617 19.2458 11.7191 20.0109 10.9006 20.6305C10.7782 20.707 10.7247 20.8065 10.7247 20.9289C10.7247 21.0513 10.7323 21.1507 10.7553 21.2272L14.2205 28.9617C14.297 29.0994 14.3429 29.2295 14.3429 29.3519C14.3429 29.5737 14.2052 29.6808 13.9222 29.6808H11.4743C11.2525 29.6808 11.0918 29.6579 10.9848 29.6196C10.8777 29.5814 10.7782 29.4819 10.7017 29.3213L7.596 22.3289C7.53481 22.2065 7.45831 22.13 7.35887 22.0917C7.25942 22.0535 7.06053 22.0305 6.7622 22.0305H4.22255C3.98541 22.0305 3.80183 22.0688 3.67178 22.1376C3.54174 22.2065 3.48054 22.3518 3.48054 22.5737V29.1453C3.48054 29.306 3.427 29.436 3.3199 29.5355C3.21281 29.6349 3.07512 29.6885 2.91448 29.6885H0.566067Z" fill="white"/>
                <path d="M23.1552 27.5081C21.9236 26.4524 20.9751 25.1901 20.3172 23.7289C19.6593 22.2677 19.3304 20.7453 19.3304 19.1693C19.3304 17.9376 19.5369 16.7212 19.9424 15.5431C20.3478 14.3573 20.9598 13.2786 21.7783 12.2994C22.7574 11.1059 23.8972 10.1802 25.2129 9.53759C26.5286 8.88732 27.9132 8.52775 29.3666 8.4436H29.4278C29.5885 8.4436 29.7185 8.5048 29.8332 8.61956C29.9403 8.74196 30.0092 8.88732 30.0245 9.07093V11.672C30.0245 12.0469 29.8179 12.2611 29.3972 12.2994C28.5022 12.3759 27.6455 12.636 26.827 13.0644C26.0085 13.4928 25.32 14.0666 24.7692 14.7857C23.6907 16.1015 23.1552 17.5857 23.1552 19.2382C23.1552 20.2327 23.3617 21.1966 23.7824 22.1223C24.2032 23.048 24.7998 23.8436 25.5724 24.4939C26.8882 25.5726 28.3569 26.1081 29.9939 26.1081C30.9883 26.1081 31.9445 25.9016 32.8625 25.4808C33.7804 25.06 34.553 24.4557 35.1956 23.66C35.731 23.0251 36.1365 22.3212 36.4042 21.5562C36.6719 20.7912 36.8096 20.0108 36.8096 19.2076C36.8096 18.0753 36.5572 17.0196 36.06 16.0403C35.9605 15.8414 35.907 15.6425 35.907 15.4436C35.907 15.1835 36.0294 14.954 36.2665 14.7551C36.4272 14.6556 36.7331 14.4491 37.1921 14.1278C37.6511 13.8065 37.9418 13.6076 38.0565 13.531C38.2171 13.4316 38.3625 13.378 38.5078 13.378C38.8291 13.378 39.0662 13.531 39.2269 13.8294C40.1831 15.5048 40.6574 17.3026 40.6574 19.2382C40.6574 20.4928 40.4508 21.7092 40.0301 22.895C39.6094 24.0808 38.9974 25.1595 38.1789 26.1387C37.1998 27.3934 35.9835 28.3573 34.5224 29.0229C33.0614 29.6885 31.5314 30.0251 29.9403 30.0251C28.6858 30.0251 27.4695 29.8109 26.2991 29.3825C25.1211 28.954 24.0808 28.3344 23.1628 27.5158L23.1552 27.5081Z" fill="white"/>
                <path d="M36.7943 10.9758C36.9473 11.1059 36.9703 11.1365 37.1156 11.2666C37.3374 11.4578 37.2992 11.9933 36.9932 12.1999C36.3966 12.6589 35.7693 13.1485 35.1879 13.6152C34.6831 14.013 34.2623 13.8065 34.0022 13.6076C33.4209 13.1562 32.7324 12.8502 32.0822 12.613C31.5544 12.4218 31.5773 12.1004 31.5773 11.8939L31.6232 9.43049C31.6232 9.13213 31.8527 8.64251 32.4264 8.74196C34.0175 9.02502 36.0906 10.3332 36.779 10.9911L36.7943 10.9758Z" fill="#EF7C00"/>
                <path d="M47.4196 29.6885C47.2589 29.6885 47.1289 29.6349 47.0141 29.5278C46.907 29.4207 46.8535 29.2907 46.8535 29.153V9.31573C46.8535 9.15508 46.9147 9.02502 47.0294 8.91027C47.1518 8.80317 47.2819 8.74961 47.4196 8.74961H50.4105C50.5482 8.74961 50.6706 8.80317 50.7854 8.91027C50.9001 9.01737 50.946 9.15508 50.946 9.31573V29.153C50.946 29.2907 50.9001 29.4131 50.793 29.5278C50.6936 29.6349 50.5635 29.6885 50.4029 29.6885H47.4119H47.4196Z" fill="white"/>
                <path d="M88.2375 12.4524C88.0999 12.4524 87.9775 12.3988 87.8627 12.2917C87.7556 12.1846 87.7021 12.0469 87.7021 11.8862V9.31573C87.7021 9.15508 87.7556 9.02502 87.8627 8.92557C87.9698 8.82612 88.0999 8.77256 88.2375 8.77256H102.099C102.259 8.77256 102.389 8.82612 102.504 8.93322C102.611 9.04032 102.665 9.17038 102.665 9.30809V11.8786C102.665 12.0163 102.611 12.1463 102.504 12.2687C102.397 12.3912 102.259 12.4447 102.099 12.4447H88.2375V12.4524ZM88.2375 29.6885C88.0999 29.6885 87.9698 29.6349 87.8627 29.5278C87.7556 29.4207 87.7021 29.2907 87.7021 29.153V14.7627C87.7021 14.6021 87.7556 14.472 87.8627 14.3726C87.9698 14.2731 88.0999 14.2196 88.2375 14.2196H90.7772C90.9378 14.2196 91.0679 14.2655 91.1673 14.3726C91.2668 14.4797 91.3127 14.6021 91.3127 14.7627V17.5016C91.3127 17.8764 91.5727 18.0677 92.0929 18.0677H99.6201C99.7578 18.0677 99.8802 18.1136 99.9796 18.2054C100.079 18.2972 100.133 18.4119 100.133 18.5496V21.1201C100.133 21.2578 100.079 21.3726 99.9796 21.4644C99.8802 21.5562 99.7578 21.6021 99.6201 21.6021H92.0011C91.5421 21.6021 91.3127 21.8087 91.3127 22.2294V25.4885C91.3127 25.6262 91.3739 25.7562 91.4886 25.8786C91.611 26.001 91.741 26.0546 91.8787 26.0546H102.152C102.313 26.0546 102.443 26.1005 102.542 26.2076C102.642 26.307 102.695 26.4371 102.695 26.5978V29.1683C102.695 29.306 102.642 29.4284 102.542 29.5431C102.443 29.6502 102.313 29.7038 102.152 29.7038H88.2299L88.2375 29.6885Z" fill="white"/>
                <path d="M118.147 8.59661C118.568 8.59661 118.851 8.70371 118.981 8.92557L121.544 13.1256C121.674 13.3398 121.804 13.531 121.788 13.6688C121.773 13.8677 121.651 14.0589 121.513 14.2655C121.406 14.4185 121.176 14.7627 120.848 15.2906C120.519 15.8185 120.266 16.1781 120.098 16.4458C119.884 16.7748 119.517 16.8666 119.31 16.5376L114.889 9.35399C114.82 9.24688 114.766 9.12448 114.766 8.96382C114.766 8.72666 114.889 8.60426 115.126 8.60426H118.14L118.147 8.59661Z" fill="#EF7C00"/>
                <path d="M130.784 8.59661C131.021 8.59661 131.144 8.71901 131.144 8.95617C131.144 9.11683 131.106 9.24688 131.021 9.34634L125.139 18.7868L131.381 28.7628C131.457 28.9005 131.503 29.0535 131.503 29.2142C131.503 29.436 131.404 29.5431 131.205 29.5431L128.13 29.5125H127.954C127.617 29.5125 127.396 29.436 127.296 29.2754L122.966 22.283L118.606 29.2754C118.545 29.3748 118.453 29.436 118.323 29.4666C118.193 29.4972 117.987 29.5125 117.711 29.5125L114.72 29.5431C114.521 29.5431 114.422 29.436 114.422 29.2142C114.422 29.0535 114.46 28.9081 114.544 28.7628L126.914 8.92557C127.074 8.70371 127.365 8.59661 127.778 8.59661H130.792H130.784Z" fill="white"/>
                <path d="M73.1144 38.1574C73.1144 37.8743 73.0455 37.6142 72.9002 37.3694C72.7549 37.1246 72.5636 36.9257 72.3188 36.7726C72.0741 36.6273 71.8063 36.5508 71.508 36.5508C71.0796 36.5508 70.6895 36.6961 70.3452 36.9792C70.001 37.2623 69.8327 37.6601 69.8327 38.1803C69.8327 38.6546 69.9781 39.0601 70.2764 39.389C70.5747 39.718 70.9725 39.8863 71.4697 39.8863C71.7987 39.8863 72.0893 39.8022 72.3418 39.6339C72.5942 39.4656 72.7855 39.2513 72.9155 38.9836C73.0455 38.7158 73.1144 38.4404 73.1144 38.1574ZM73.1144 35.8087H74.0629V40.047C74.0629 40.8579 73.9635 41.4699 73.7646 41.8983C73.5657 42.3268 73.275 42.6175 72.8925 42.7705C72.5101 42.9235 72.0052 43 71.3779 43C71.1102 43 70.8501 42.9923 70.6053 42.9694C70.3605 42.9464 70.1158 42.9082 69.8633 42.8546C69.6109 42.8011 69.3585 42.7169 69.106 42.6175L69.3432 41.6918C70.001 41.9672 70.7124 42.1049 71.485 42.1049C72.0205 42.1049 72.4259 41.9748 72.7013 41.7224C72.9767 41.4699 73.1144 40.988 73.1144 40.2765V39.894C72.8925 40.1694 72.6172 40.3759 72.2959 40.5289C71.9746 40.6819 71.6457 40.7585 71.3014 40.7585C70.9343 40.7585 70.5977 40.6896 70.2917 40.5442C69.9857 40.4065 69.718 40.2153 69.4961 39.9705C69.2743 39.7257 69.1137 39.4503 68.9913 39.1442C68.8765 38.8382 68.8153 38.5169 68.8153 38.1956C68.8153 37.6983 68.9301 37.2623 69.1672 36.8874C69.3967 36.5125 69.7027 36.2142 70.0775 36.0153C70.4523 35.8087 70.8348 35.7093 71.2249 35.7093C71.5845 35.7093 71.9287 35.7781 72.25 35.9235C72.5789 36.0688 72.862 36.283 73.1067 36.5661V35.8317L73.1144 35.8087Z" fill="white"/>
                <path d="M59.8271 40.6284V35.8164H60.7833V36.8186C61.051 36.0688 61.5253 35.694 62.1985 35.694C62.5045 35.694 62.7951 35.7475 63.0782 35.8546C63.3612 35.9617 63.5983 36.1453 63.7819 36.4131C63.9732 36.6808 64.065 37.0328 64.065 37.4765V40.6284H63.1088V37.7289C63.1088 37.4612 63.0476 37.2011 62.9252 36.941C62.8028 36.6808 62.5351 36.5508 62.1143 36.5508C61.8848 36.5508 61.663 36.612 61.4718 36.7267C61.2729 36.8492 61.1199 37.0175 60.9975 37.2317C60.8751 37.4459 60.8063 37.6983 60.791 37.989V40.6207H59.8348L59.8271 40.6284Z" fill="white"/>
                <path d="M54.9544 33.2918H54.0058V34.3475H54.9544V33.2918Z" fill="white"/>
                <path d="M54.9544 35.8087H54.0058V40.6207H54.9544V35.8087Z" fill="white"/>
                <path d="M47.4043 40.6284V36.6808H46.4481V35.8087H47.4043V34.0797L48.3604 33.5978V35.8087H49.3166V36.6808H48.3604V40.6284H47.4043Z" fill="white"/>
                <path d="M38.0642 37.5453H40.7262C40.6727 37.224 40.535 36.9716 40.2902 36.7879C40.053 36.6043 39.7777 36.5125 39.4564 36.5125C39.1121 36.5125 38.8138 36.6043 38.5614 36.7956C38.3089 36.9792 38.1406 37.2317 38.0642 37.5453ZM41.1469 39.2207L41.8201 39.7563C41.5065 40.1388 41.1622 40.4065 40.8027 40.5366C40.4432 40.6743 40.0301 40.7432 39.5788 40.7432C38.9897 40.7432 38.4925 40.6284 38.0948 40.3912C37.697 40.1541 37.4063 39.8404 37.2151 39.4503C37.0238 39.0601 36.932 38.624 36.932 38.1421C36.932 37.6601 37.0391 37.247 37.261 36.8721C37.4751 36.4972 37.7811 36.2065 38.1789 36C38.5767 35.7934 39.028 35.6863 39.5405 35.6863C40.1142 35.6863 40.5809 35.8164 40.9327 36.0765C41.2846 36.3366 41.5294 36.6732 41.6747 37.0787C41.8201 37.4841 41.8889 37.9278 41.8889 38.4022H37.9571C37.9571 38.6546 38.0259 38.8994 38.1636 39.1289C38.3013 39.3584 38.4925 39.5421 38.745 39.6798C38.9974 39.8175 39.2728 39.8863 39.5941 39.8863C40.2443 39.8863 40.7644 39.6645 41.1622 39.2131L41.1469 39.2207Z" fill="white"/>
                <path d="M28.4104 40.6284V32.9169H29.3666V37.8513L31.0495 35.8087H32.3652L30.2769 37.9125L32.61 40.6284H31.3402L29.7567 38.4098L29.3666 38.8229V40.6284H28.4104Z" fill="white"/>
                <path d="M20.8756 40.6284V35.8164H21.8242V36.9716C22.329 36.1224 22.8339 35.694 23.3388 35.694H23.6142V36.6885H23.2929C22.9639 36.6885 22.658 36.8032 22.3902 37.0251C22.1225 37.247 21.9313 37.5836 21.8165 38.0196V40.636H20.868L20.8756 40.6284Z" fill="white"/>
                <path d="M12.7441 39.1595C12.7441 39.3508 12.8283 39.5191 13.0042 39.6645C13.1802 39.8098 13.4479 39.8863 13.8227 39.8863C14.2817 39.8863 14.603 39.8098 14.7789 39.6568C14.9549 39.5038 15.0467 39.3355 15.0467 39.1595C15.0467 38.9989 14.9472 38.8459 14.7483 38.7082C14.5494 38.5705 14.2588 38.5016 13.8763 38.5016C13.4938 38.5016 13.2108 38.5705 13.0272 38.7082C12.8436 38.8459 12.7518 38.9989 12.7518 39.1595H12.7441ZM12.5376 36.8568L12.3464 36.0229C12.9277 35.8011 13.5168 35.694 14.1134 35.694C14.7101 35.694 15.1385 35.824 15.4827 36.0688C15.8269 36.3213 15.9952 36.6885 15.9952 37.1705V40.6284H15.039V40.3453C14.5571 40.6207 14.0599 40.7508 13.5321 40.7508C12.9583 40.7508 12.507 40.5901 12.1934 40.2688C11.8797 39.9475 11.7191 39.5727 11.7191 39.1595C11.7191 38.9377 11.788 38.7082 11.918 38.4787C12.048 38.2492 12.2699 38.0502 12.5682 37.8896C12.8665 37.7289 13.249 37.6524 13.7233 37.6524C14.1976 37.6524 14.6412 37.7595 15.0314 37.9737V37.7289C15.0314 37.4 14.9702 37.1246 14.8554 36.9027C14.733 36.6808 14.45 36.5661 13.9987 36.5661C13.4556 36.5661 12.966 36.6655 12.53 36.8645L12.5376 36.8568Z" fill="white"/>
                <path d="M0 35.8164H0.948544V36.5508C1.23158 35.977 1.6829 35.694 2.30251 35.694C2.63144 35.694 2.93743 35.7858 3.22046 35.9694C3.50349 36.153 3.69473 36.4054 3.80183 36.7344C3.90127 36.4284 4.10016 36.1836 4.39084 35.9923C4.68152 35.8011 5.0028 35.7016 5.36233 35.7016C5.79836 35.7016 6.17319 35.8546 6.50212 36.1606C6.8234 36.4666 6.98404 36.9104 6.98404 37.4994V40.636H6.02784V37.6218C6.02784 37.2393 5.93605 36.9716 5.76011 36.8032C5.58417 36.6426 5.35468 36.5584 5.0793 36.5584C4.75037 36.5584 4.49029 36.6655 4.2761 36.8874C4.06956 37.1016 3.97012 37.4153 3.97012 37.8207V40.6437H3.01392V37.7978C3.01392 37.4306 2.95273 37.1322 2.82268 36.9027C2.69264 36.6732 2.42491 36.5584 2.01948 36.5584C1.7135 36.5584 1.46106 36.6808 1.26218 36.9333C1.06329 37.1858 0.963843 37.4841 0.948544 37.8284V40.636H0V35.8164Z" fill="white"/>
                <path d="M70.6895 29.6655C70.5288 29.6655 70.4141 29.6196 70.3299 29.5202C70.2535 29.4207 70.2076 29.3672 70.2076 29.3672C69.435 28.1508 68.1651 26.177 66.3981 23.4535C64.6387 20.7223 63.3077 18.6414 62.4127 17.2108C62.2826 17.0578 62.1449 16.8742 62.1449 17.119V20.8218C62.1449 20.9595 62.099 21.0895 61.9996 21.1966C61.9002 21.3038 61.7701 21.3573 61.6095 21.3573H58.7103C58.5496 21.3573 58.4196 21.3114 58.3049 21.2119C58.1978 21.1125 58.1442 20.9824 58.1442 20.8218V9.40754C58.1442 9.24688 58.1978 9.11683 58.3049 9.00207C58.412 8.89497 58.5496 8.84142 58.7103 8.84142H61.6401C61.7778 8.84142 61.9002 8.90262 61.9996 9.01737L69.2284 21.0589C69.4044 21.3956 69.5573 21.5639 69.6797 21.5639C69.7792 21.5639 69.8327 21.3956 69.8327 21.0589L69.8021 9.40754C69.8021 9.24688 69.8557 9.11683 69.9704 9.00207C70.0775 8.89497 70.2076 8.84142 70.3452 8.84142H73.5122C73.6728 8.84142 73.8028 8.89497 73.9176 9.00207C74.0247 9.10918 74.0859 9.24688 74.0859 9.40754V29.1224C74.0859 29.283 74.0323 29.4131 73.9176 29.5125C73.8105 29.612 73.6728 29.6579 73.5122 29.6579H70.7048L70.6895 29.6655Z" fill="white"/>
                <path d="M58.5955 23.1398H61.6783C61.9307 23.1398 62.1373 23.3464 62.1373 23.5988V29.2218C62.1373 29.4743 61.9307 29.6808 61.6783 29.6808H58.5955C58.3431 29.6808 58.1366 29.4743 58.1366 29.2218V23.5988C58.1366 23.3464 58.3431 23.1398 58.5955 23.1398Z" fill="#EF7C00"/>
            </svg>
        </a>
    )
}

export default RoinextLogo;