import React, { useEffect, useState } from 'react'
import './css/share.css'

interface ShareProps {
    value: number;
    onion: number;
}

const link = 'https://meatmeter.ru';

const Share:React.FC<ShareProps> = ({value, onion}) => {
    const [text, setText] = useState('');

    useEffect(() => {
        const message = `Нам нужно ${value} кг мяса.\n Для маринада нужно ${onion} кг лука.`;
        setText(message);
    }, [value, onion]);

    return (
        <div className={`share ${value === 0 ? '' : 'visible'}`}>
            Отправить инфу: 
            <a href={encodeURI(`https://api.whatsapp.com/send/?text=${text}\n ${link}&type=custom_url&app_absent=1`)} className='ws' target='_blank' onClick={(e) => {if (value === 0) e.preventDefault();}}>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.584473 22.83L2.12495 17.2023C1.17437 15.555 0.674874 13.6876 0.675787 11.7727C0.678527 5.78608 5.55017 0.914429 11.5358 0.914429C14.4406 0.915342 17.1672 2.04673 19.2182 4.09949C21.2682 6.15225 22.3968 8.88074 22.3959 11.7827C22.3932 17.7703 17.5215 22.6419 11.5358 22.6419C9.71869 22.641 7.928 22.1853 6.34187 21.3197L0.584473 22.83ZM6.6085 19.3537C8.13894 20.2622 9.59998 20.8065 11.5322 20.8074C16.507 20.8074 20.5596 16.7585 20.5623 11.7809C20.5641 6.79328 16.5308 2.74986 11.5395 2.74803C6.56102 2.74803 2.51121 6.79694 2.50939 11.7736C2.50847 13.8054 3.10385 15.3267 4.10374 16.9183L3.19151 20.2495L6.6085 19.3537ZM17.0065 14.3642C16.9389 14.251 16.7581 14.1834 16.486 14.0473C16.2148 13.9113 14.8807 13.2547 14.6314 13.1643C14.383 13.0739 14.2022 13.0283 14.0205 13.3004C13.8397 13.5716 13.3192 14.1834 13.1613 14.3642C13.0033 14.545 12.8444 14.5678 12.5732 14.4318C12.302 14.2957 11.4272 14.0099 10.3908 13.0849C9.58445 12.3653 9.0393 11.4768 8.88133 11.2047C8.72335 10.9335 8.86489 10.7865 9.00004 10.6513C9.1224 10.5299 9.27124 10.3345 9.4073 10.1756C9.54519 10.0185 9.58993 9.9053 9.68125 9.72358C9.77165 9.54278 9.7269 9.38389 9.65842 9.24783C9.58993 9.11268 9.04752 7.77675 8.82197 7.23342C8.60099 6.70471 8.37727 6.77593 8.21108 6.76772L7.69058 6.75858C7.50978 6.75858 7.21575 6.82616 6.96737 7.09828C6.719 7.37039 6.0177 8.02604 6.0177 9.36197C6.0177 10.6979 6.9902 11.9882 7.12535 12.169C7.2614 12.3498 9.03839 15.0911 11.7605 16.2663C12.4079 16.5457 12.9138 16.7128 13.3074 16.8379C13.9575 17.0443 14.5492 17.0151 15.0168 16.9457C15.5382 16.8681 16.6221 16.2891 16.8485 15.6554C17.075 15.0208 17.075 14.4774 17.0065 14.3642Z" fill="#EF7C00"/>
                </svg>
            </a>
            <a href={encodeURI(`https://telegram.me/share/url?url=${link}&text=${text}`)} className='tg' target='_blank' onClick={(e) => {if (value === 0) e.preventDefault();}}>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_39_367)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.4156 11.8722C22.4156 17.924 17.5096 22.83 11.4578 22.83C5.40597 22.83 0.5 17.924 0.5 11.8722C0.5 5.8204 5.40597 0.914429 11.4578 0.914429C17.5096 0.914429 22.4156 5.8204 22.4156 11.8722ZM11.8505 9.00395C10.7847 9.44726 8.65455 10.3648 5.46013 11.7565C4.94141 11.9628 4.66967 12.1646 4.64494 12.3619C4.60313 12.6954 5.02074 12.8267 5.58941 13.0056C5.66676 13.0299 5.74691 13.0551 5.82908 13.0818C6.38857 13.2637 7.14118 13.4764 7.53243 13.4849C7.88733 13.4925 8.28344 13.3462 8.72076 13.0459C11.7054 11.0312 13.2461 10.0129 13.3428 9.99092C13.4111 9.97543 13.5056 9.95596 13.5697 10.0129C13.6338 10.0699 13.6275 10.1777 13.6207 10.2066C13.5793 10.383 11.94 11.907 11.0917 12.6957C10.8273 12.9415 10.6397 13.1159 10.6013 13.1558C10.5154 13.245 10.4279 13.3294 10.3437 13.4105C9.82396 13.9116 9.43417 14.2873 10.3653 14.9009C10.8128 15.1958 11.1708 15.4397 11.5281 15.6829C11.9182 15.9486 12.3073 16.2136 12.8107 16.5436C12.939 16.6277 13.0615 16.715 13.1808 16.8001C13.6348 17.1237 14.0427 17.4145 14.5466 17.3681C14.8394 17.3412 15.1419 17.0659 15.2955 16.2447C15.6585 14.3041 16.372 10.0993 16.5369 8.36662C16.5514 8.21481 16.5332 8.02053 16.5186 7.93525C16.504 7.84996 16.4735 7.72845 16.3627 7.63849C16.2314 7.53196 16.0287 7.5095 15.938 7.5111C15.5259 7.51836 14.8936 7.73823 11.8505 9.00395Z" fill="#EF7C00"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_39_367">
                    <rect width="21.9156" height="21.9156" fill="white" transform="translate(0.5 0.914429)"/>
                    </clipPath>
                    </defs>
                </svg>
            </a>
        </div>
    )
}

export default Share; 