import React from 'react'
import './css/switch.css';
import Icon from './Icon';

interface SwitchProps {
    count: number;
    counterHandler: (plus?: boolean) => void;
    label: string;
}

const Switch: React.FC<SwitchProps> = ({count, counterHandler, label}) => {
    return (
        <div className="switch-wrapper">
            <div className="icon">
                <Icon type={label} />
            </div>
            <div className='switch-box'>
                <div className="switch-btn" onClick={() => counterHandler(true)}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 11C6.55228 11 7 11.4477 7 12V16C7 16.5523 7.44772 17 8 17H10C10.5523 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11H16C16.5523 11 17 10.5523 17 10V8C17 7.44772 16.5523 7 16 7H12C11.4477 7 11 6.55228 11 6V2C11 1.44772 10.5523 1 10 1H8C7.44771 1 7 1.44772 7 2V6C7 6.55228 6.55228 7 6 7H2C1.44772 7 1 7.44772 1 8V10C1 10.5523 1.44772 11 2 11H6Z" fill="#EF7C00"/>
                        <path d="M6.5 12V16C6.5 16.8284 7.17157 17.5 8 17.5H10C10.8284 17.5 11.5 16.8284 11.5 16V12C11.5 11.7239 11.7239 11.5 12 11.5H16C16.8284 11.5 17.5 10.8284 17.5 10V8C17.5 7.17157 16.8284 6.5 16 6.5H12C11.7239 6.5 11.5 6.27614 11.5 6V2C11.5 1.17157 10.8284 0.5 10 0.5H8C7.17157 0.5 6.5 1.17157 6.5 2V6C6.5 6.27614 6.27614 6.5 6 6.5H2C1.17157 6.5 0.5 7.17157 0.5 8V10C0.5 10.8284 1.17157 11.5 2 11.5H6C6.27614 11.5 6.5 11.7239 6.5 12Z" stroke="#EF7C00" strokeOpacity="0.1"/>
                    </svg>
                </div>
                    <div className="switch-counter">
                        {count}
                    </div>
                <div className="switch-btn" onClick={() => counterHandler(false)}>
                    <svg width="18" height="6" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="16" height="4" rx="1" fill="#FA5D10"/>
                        <rect x="0.5" y="0.5" width="17" height="5" rx="1.5" stroke="#FA5D10" strokeOpacity="0.1"/>
                    </svg>
                </div>
            </div>
            <div className="switch-label">{label}</div>
        </div>
  )
}

export default Switch;